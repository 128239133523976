exports.components = {
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-details-tsx": () => import("./../../../src/pages/blog-details.tsx" /* webpackChunkName: "component---src-pages-blog-details-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-coming-soon-tsx": () => import("./../../../src/pages/coming-soon.tsx" /* webpackChunkName: "component---src-pages-coming-soon-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dziekujemy-za-kontakt-np-tsx": () => import("./../../../src/pages/dziekujemy-za-kontakt-np.tsx" /* webpackChunkName: "component---src-pages-dziekujemy-za-kontakt-np-tsx" */),
  "component---src-pages-dziekujemy-za-kontakt-tsx": () => import("./../../../src/pages/dziekujemy-za-kontakt.tsx" /* webpackChunkName: "component---src-pages-dziekujemy-za-kontakt-tsx" */),
  "component---src-pages-in-building-tsx": () => import("./../../../src/pages/in-building.tsx" /* webpackChunkName: "component---src-pages-in-building-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kredyty-hipoteczne-tsx": () => import("./../../../src/pages/kredyty-hipoteczne.tsx" /* webpackChunkName: "component---src-pages-kredyty-hipoteczne-tsx" */),
  "component---src-pages-nadplaty-tsx": () => import("./../../../src/pages/nadplaty.tsx" /* webpackChunkName: "component---src-pages-nadplaty-tsx" */),
  "component---src-pages-oddluzanie-tsx": () => import("./../../../src/pages/oddluzanie.tsx" /* webpackChunkName: "component---src-pages-oddluzanie-tsx" */),
  "component---src-pages-platnosci-tsx": () => import("./../../../src/pages/platnosci.tsx" /* webpackChunkName: "component---src-pages-platnosci-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-project-details-tsx": () => import("./../../../src/pages/project-details.tsx" /* webpackChunkName: "component---src-pages-project-details-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-promocje-bankowe-offers-200-pekao-osobiste-tsx": () => import("./../../../src/pages/promocje-bankowe/offers/200-pekao-osobiste.tsx" /* webpackChunkName: "component---src-pages-promocje-bankowe-offers-200-pekao-osobiste-tsx" */),
  "component---src-pages-promocje-bankowe-offers-200-pko-osobiste-tsx": () => import("./../../../src/pages/promocje-bankowe/offers/200-pko-osobiste.tsx" /* webpackChunkName: "component---src-pages-promocje-bankowe-offers-200-pko-osobiste-tsx" */),
  "component---src-pages-promocje-bankowe-offers-2000-pekao-firma-tsx": () => import("./../../../src/pages/promocje-bankowe/offers/2000-pekao-firma.tsx" /* webpackChunkName: "component---src-pages-promocje-bankowe-offers-2000-pekao-firma-tsx" */),
  "component---src-pages-promocje-bankowe-offers-550-mbank-osobiste-tsx": () => import("./../../../src/pages/promocje-bankowe/offers/550-mbank-osobiste.tsx" /* webpackChunkName: "component---src-pages-promocje-bankowe-offers-550-mbank-osobiste-tsx" */),
  "component---src-pages-promocje-bankowe-offers-650-velo-osobiste-tsx": () => import("./../../../src/pages/promocje-bankowe/offers/650-velo-osobiste.tsx" /* webpackChunkName: "component---src-pages-promocje-bankowe-offers-650-velo-osobiste-tsx" */),
  "component---src-pages-promocje-bankowe-offers-bnp-osobiste-gotowka-tsx": () => import("./../../../src/pages/promocje-bankowe/offers/bnp-osobiste-gotowka.tsx" /* webpackChunkName: "component---src-pages-promocje-bankowe-offers-bnp-osobiste-gotowka-tsx" */),
  "component---src-pages-promocje-bankowe-tsx": () => import("./../../../src/pages/promocje-bankowe.tsx" /* webpackChunkName: "component---src-pages-promocje-bankowe-tsx" */),
  "component---src-pages-restrukturyzacja-tsx": () => import("./../../../src/pages/restrukturyzacja.tsx" /* webpackChunkName: "component---src-pages-restrukturyzacja-tsx" */),
  "component---src-pages-service-details-tsx": () => import("./../../../src/pages/service-details.tsx" /* webpackChunkName: "component---src-pages-service-details-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */),
  "component---src-pages-zwrot-kosztow-tsx": () => import("./../../../src/pages/zwrot-kosztow.tsx" /* webpackChunkName: "component---src-pages-zwrot-kosztow-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

